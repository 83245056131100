<template>
  <div class="ProjectQrcode">
    <ProjectMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click.stop="onShowNewData"
        >新建</el-button
      >
    </div>
    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="dateList" style="width: 100%">
        <el-table-column prop="id" width="100" label="ID"></el-table-column>
        <el-table-column prop="title" label="公示标题"></el-table-column>
        <el-table-column prop="createTime" label="添加时间"></el-table-column>
        <el-table-column label="二维码">
          <template slot-scope="scope">
            <a @click="getQrCode(scope.row)">查看二维码</a>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-delete"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50, 100]"
        :total="totals"
        @current-change="getDataList"
        @size-change="getDataList"
      >
      </el-pagination>
    </div>
    <!-- 二维码 -->
    <div class="qrCodeWrap" v-show="showQrCode" @click="showQrCode = false">
      <h2>{{ qrCodeName }}</h2>
      <div id="qrcode"></div>
      <el-button @click="downLoad">立即下载</el-button>
    </div>
    <!-- 新建安检点 -->
    <el-dialog
      title="项目公示"
      :visible.sync="showNewData"
      :before-close="onCloseNewData"
      style="width: 1200px; margin: 0 auto"
    >
      <el-form>
        <el-form-item>
          <el-input v-model="title" autocomplete="off" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <div id="demo1"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        
        <el-upload
          action="/"
          :show-file-list="false"
          :auto-upload="true"
          :http-request="addFile"
        >
          <el-button size="small">添加附件</el-button>
          <el-button size="small" @click.stop="onCloseNewData">取消</el-button>
          <el-button size="small" type="primary" @click.stop="onSave">确定</el-button>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.ProjectQrcode {
  .el-form-item {
    margin-bottom: 0;
  }
  .fileIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .fileName {
    font-size: 14px;
    line-height: 16px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }

  .qrCodeWrap {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #qrcode {
      margin: 30px;
    }
    h2 {
      font-size: 40px;
      color: #fff;
    }
  }
}
</style>

<script>
import wangEditor from "wangeditor";
import ProjectMenu from "@/components/ProjectMenu.vue";
import QRCode from "qrcodejs2";

// import "@wangeditor/editor/dist/css/style.css";
// import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default {
  name: "ProjectQrcode",
  components: {
    ProjectMenu,
    // Editor,
    // Toolbar,
  },
  data() {
    return {
      loading: null,
      projectList: [],
      currentProject: null,

      dateList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      currentRow: null,
      qrCode: "",
      qrCodeName: "",
      showQrCode: false,

      showNewData: false,
      editor: null,
      id: null,
      title: null,
      mainBody: null,
    };
  },
  computed: {},
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (newVal, oldVal) {
      this.getDataList(); // 获取列表
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取列表
    getDataList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/qrcode/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.dateList = res.data.dateList;
          that.dateList.forEach((item) => {
            item.mainBody = that.escape2Html(item.mainBody);
          });
          that.totals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 创建编辑器
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 取得二维码
    getQrCode: function (row) {
      var that = this;
      this.qrCode = that.$qrcode + row.id;
      console.log('getQrCode =>', this.qrCode)
      this.qrCodeName = row.title;
      document.getElementById("qrcode").innerHTML = "";
      var qrcode = new QRCode("qrcode", {
        width: 300,
        height: 300,
        text: that.qrCode,
        colorDark: "#000",
        colorLight: "#fff",
      });
      this.showQrCode = true;
    },
    // 下载二维码
    downLoad: function () {
      let myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = this.qrCodeName;
      a.click();
      this.$message({
        message: "正在进行下载保存",
        type: "success",
      });
    },
    // 删除
    onDel: function (row) {
      var that = this;
      var data = {
        id: row.id,
      };
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/qrcode/del",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getDataList();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 添加
    onAdd: function () {
      //   var that = this;
      //   var data = {
      //     dotId: "",
      //     projectId: this.currentProject,
      //     dotName: this.newDotName,
      //   };
      //   that.loading = that.$loading({
      //     lock: true,
      //     text: "Loading...",
      //     spinner: "el-icon-loading",
      //     background: "rgba(0, 0, 0, 0.7)",
      //   });
      //   that
      //     .$axiosAdmin({
      //       method: "post",
      //       url: "api/admin/safetyDot/saveDot",
      //       data: JSON.stringify(data),
      //     })
      //     .then((res) => {
      //       that.$message({
      //         type: "success",
      //         message: "添加成功!",
      //       });
      //       that.getDotList();
      //       that.showNewDot = false;
      //       that.loading.close();
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       that.loading.close();
      //     });
    },
    onEdit: function (row) {
      this.showNewData = true;
      this.id = row.id;
      this.title = row.title;
      this.editor = null;
      this.mainBody = row.mainBody;
      setTimeout(() => {
        this.initEditor();
      }, 1);
    },
    onSave: function () {
      var that = this;
      var data = {
        id: this.id,
        projectId: this.currentProject,
        title: this.title,
        mainBody: this.html2Escape(this.mainBody),
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/qrcode/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.$message({
            type: "success",
            message: "添加成功!",
          });
          that.getDataList();
          that.onCloseNewData();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开新建
    onShowNewData: function () {
      this.showNewData = true;
      this.id = null;
      this.title = null;
      this.editor = null;
      this.mainBody = null;
      setTimeout(() => {
        this.initEditor();
      }, 1);
    },
    onCloseNewData: function () {
      this.showNewData = false;
      this.id = null;
      this.title = null;
      this.mainBody = null;
      this.destroyEditor();
    },
    // 创建编辑器
    initEditor: function () {
      var that = this;
      const editor = new wangEditor(`#demo1`);
      editor.config.uploadImgServer = "api/admin/qrcode/upload";
      editor.config.uploadImgMaxLength = 1;
      editor.config.uploadFileName = "file";
      editor.config.uploadImgHeaders = {
        Authorization: "Bearer " + localStorage.token,
        // "Content-Type": "multipart/form-data",
      };
      editor.config.onchange = (newHtml) => {
        that.mainBody = newHtml;
      };
      editor.create();
      editor.txt.html(this.mainBody);
      this.editor = editor;
    },
    // 销毁编辑器
    destroyEditor: function () {
      this.editor.destroy();
      this.editor = null;
    },
    // 编码
    html2Escape: function (sHtml) {
      return sHtml.replace(/[<>&"]/g, function (c) {
        return { "<": "&lt;", ">": "&gt;", "&": "&amp;", '"': "&quot;" }[c];
      });
    },
    // 解码
    escape2Html: function (str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    // 添加附件
    addFile: function (file) {
      var that = this
      that.fileFormData = new FormData(); 
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      that
        .$axios({
          method: "post",
          url: "api/admin/qrcode/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('upload =>', res);
          that.editor.txt.append(`<p>附件：<a href="${res.data.data[0].url}">点击下载</a></p>`);
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
      
    },
  },
};
</script>